export const detailsInformation = [
    {
        heading: ' Q1: Who are we?',
        text: `
         Avangers`,
        illustration: false,
        illustrationText: `
        You can think of Sclink as the medium between your school and the cloud.
        <br>
        Best illustrated in the picture above
        `
    },
    {
        heading: 'Q2: Are we cool?',
        text: `
         super cool
         `,
    },
    {
        //must be last
        heading: ' Q3: I don‘t have any Q bro!',
        text: `
          Fine!
      `,
        table: true
    },
]
