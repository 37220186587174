<main>
  <div class="rain">
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 10; --y: 4; --o: 0.2531110423757781; --a: 1.0663649861205082; --d: 0.7896705168720537; --s: 0.7903567253275441"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 35; --o: 0.45500436548536904; --a: 1.3547650580868151; --d: 0.3571439319118852; --s: 0.823965149713989"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 49; --y: 49; --o: 0.7045207865379737; --a: 1.0087885006472834; --d: 0.4598180174353663; --s: 0.24453842942359816"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 47; --y: 98; --o: 0.4091515445507421; --a: 0.7330190726575847; --d: -0.662833817123329; --s: 0.5399738078500664"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 84; --y: 93; --o: 0.44136837495338965; --a: 0.7915439402893238; --d: -0.49274814622676066; --s: 0.5928791302900955"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 14; --y: 57; --o: 0.3789632115837849; --a: 1.0455163091248216; --d: 0.009297947943522455; --s: 0.9902661997262543"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 27; --y: 16; --o: 0.4942966789048997; --a: 1.444971519290962; --d: -0.08365226335725051; --s: 0.16830965117938246"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 27; --y: 65; --o: 0.7066795031140314; --a: 1.3975805002094104; --d: 0.0197977606720241; --s: 0.1035549034382881"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 29; --y: 80; --o: 0.7255343475053; --a: 0.5087781500852804; --d: 0.977862900738157; --s: 0.380081846539958"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 21; --y: 82; --o: 0.019515977004398666; --a: 0.8458604289032814; --d: -0.04197450836348482; --s: 0.06565310940719526"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 54; --y: 33; --o: 0.8660320056703481; --a: 0.7419514305889006; --d: 0.5594156159727253; --s: 0.9863945278017223"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 69; --y: 36; --o: 0.3347834019972866; --a: 0.9001464171719069; --d: -0.1730937119439928; --s: 0.25042525663632764"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 9; --y: 16; --o: 0.5076834578749914; --a: 0.7701116711927531; --d: -0.08248980191601474; --s: 0.8107904195078208"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 27; --y: 31; --o: 0.5234218324219921; --a: 1.1474826866450787; --d: -0.3066626857007151; --s: 0.07980572109307649"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 31; --y: 45; --o: 0.9935463491534757; --a: 1.2608149395362245; --d: -0.12442271731837096; --s: 0.5490595392645687"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 46; --y: 98; --o: 0.4208505014295567; --a: 1.3182314963480386; --d: -0.9090379016134098; --s: 0.6915818955036763"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 71; --y: 49; --o: 0.4424473911076765; --a: 0.9878997147648692; --d: 0.15412989081329975; --s: 0.8473552724759104"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 64; --y: 43; --o: 0.18396329642677367; --a: 0.9448272208118895; --d: -0.1950461324865853; --s: 0.14611711518851056"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 73; --y: 84; --o: 0.2553364206281803; --a: 1.450629760640883; --d: -0.17010430224902873; --s: 0.7570355351402147"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 42; --y: 53; --o: 0.16744086174609651; --a: 0.6138839350463032; --d: 0.19087521692782516; --s: 0.7798636271263619"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 95; --y: 69; --o: 0.5143791165885501; --a: 1.4690588458566474; --d: 0.3767559039545234; --s: 0.8445162252167442"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 49; --y: 72; --o: 0.3172835817551789; --a: 1.1521772511469366; --d: 0.7640595641010792; --s: 0.756720605542683"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 88; --y: 50; --o: 0.9345285369782736; --a: 1.079238531617568; --d: -0.3786930544757312; --s: 0.05155974642817163"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 79; --y: 69; --o: 0.8216785993416822; --a: 1.1759069920891256; --d: 0.687741912284423; --s: 0.1957003209217938"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 22; --y: 38; --o: 0.5612156201809648; --a: 0.7053164069461664; --d: 0.20665744072112702; --s: 0.05343182539536362"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 68; --y: 43; --o: 0.3483800406321227; --a: 0.7791209745756882; --d: 0.40945259686818813; --s: 0.1405880231784784"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 78; --y: 41; --o: 0.8232147025870824; --a: 1.2044701634562047; --d: -0.9220432200369619; --s: 0.623466030799039"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 88; --y: 93; --o: 0.47906046133490854; --a: 0.5271560606650045; --d: -0.08623742251771294; --s: 0.30379214871382776"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 13; --y: 12; --o: 0.7163477587980034; --a: 0.5789391682225666; --d: -0.41691567566485244; --s: 0.7218907952085389"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 89; --y: 33; --o: 0.6736546889195285; --a: 1.0874331518100464; --d: 0.32416595836183015; --s: 0.5883960322194144"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 6; --y: 18; --o: 0.39336041877028816; --a: 1.4762532356086633; --d: -0.8988989427150447; --s: 0.22776100753557182"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 42; --o: 0.0611259984611936; --a: 1.3273856269813156; --d: -0.8211930296048866; --s: 0.4438761121365433"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 77; --y: 59; --o: 0.49834872817114406; --a: 1.225080176935576; --d: 0.9714084215026677; --s: 0.5341861010703008"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 98; --y: 32; --o: 0.17198068027007518; --a: 0.9832182424022973; --d: -0.33744180044093763; --s: 0.2676294352746613"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 71; --y: 76; --o: 0.26403978117533167; --a: 1.4365573450563915; --d: -0.2102580579951172; --s: 0.5668493638681016"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 62; --y: 29; --o: 0.7495972415580212; --a: 1.2986277604092469; --d: 0.06208919398325019; --s: 0.5596866331905939"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 36; --y: 86; --o: 0.270231935495451; --a: 0.5651293448481118; --d: -0.2898580884630313; --s: 0.3869991495084435"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 56; --y: 38; --o: 0.8961807595952074; --a: 0.5354664989274169; --d: 0.7015283607892027; --s: 0.09878370612284981"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 16; --y: 77; --o: 0.32257875659745316; --a: 0.7835674133176334; --d: 0.7256740991786663; --s: 0.17197593695149238"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 5; --o: 0.2121218822684403; --a: 1.4597548015963768; --d: 0.23074468827156025; --s: 0.08224326242390045"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 14; --y: 93; --o: 0.8132327924716598; --a: 1.4947104160046294; --d: -0.30338180833657935; --s: 0.04784842379746057"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 66; --y: 41; --o: 0.09829126021964152; --a: 0.8406210128275431; --d: -0.2013301475613689; --s: 0.41944150366313826"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 25; --y: 88; --o: 0.3446776840404622; --a: 0.5832807637419724; --d: 0.2604269797180585; --s: 0.22197765709931394"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 31; --y: 80; --o: 0.5466710383300382; --a: 1.0664998096758644; --d: -0.9416787349487543; --s: 0.964983747093956"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 47; --y: 83; --o: 0.12358339455172529; --a: 0.9422934927635569; --d: -0.16304993096825093; --s: 0.08651662443107311"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 37; --y: 12; --o: 0.9172761048935125; --a: 0.5519666409146362; --d: -0.6382592228955772; --s: 0.5089245593569189"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 56; --y: 76; --o: 0.6022062108752244; --a: 1.22950039945743; --d: -0.6853291907552244; --s: 0.6385632986184715"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 58; --y: 87; --o: 0.24207812005505414; --a: 1.3976475033469193; --d: -0.06607349240804306; --s: 0.7910987552093534"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 93; --y: 41; --o: 0.01118463539018344; --a: 0.5310882833102304; --d: -0.830046876418729; --s: 0.7321770979826616"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 29; --y: 41; --o: 0.3355485746825222; --a: 0.6707247591075713; --d: -0.12002562084984492; --s: 0.16779118119290537"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 75; --y: 24; --o: 0.2639028723485357; --a: 0.9232938375578637; --d: 0.17006700680590603; --s: 0.09256910447375599"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 98; --y: 74; --o: 0.9716958686973458; --a: 1.3237308959376792; --d: -0.9295053154750521; --s: 0.5476154497975201"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 48; --o: 0.07999958140175756; --a: 1.2077505427227826; --d: 0.24930840613158978; --s: 0.7128854527723669"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 19; --o: 0.9327259907606145; --a: 0.7889203533117841; --d: 0.7764614264698828; --s: 0.9554907521503992"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 38; --y: 93; --o: 0.007409402587034597; --a: 1.2364015939373274; --d: 0.365525979230668; --s: 0.3661859085883259"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 66; --y: 30; --o: 0.26644237405641014; --a: 0.5030086091369066; --d: 0.15178438240111092; --s: 0.5869087006856848"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 51; --y: 55; --o: 0.7411892049492328; --a: 1.405036548458315; --d: -0.5179597365287063; --s: 0.6865187239543111"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 94; --y: 32; --o: 0.9709517183586849; --a: 1.177451136923759; --d: 0.6730881965297812; --s: 0.41182113539173826"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 27; --y: 6; --o: 0.1891618345164423; --a: 1.0795188749957996; --d: -0.06191113950387539; --s: 0.5085882462930302"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 20; --y: 17; --o: 0.8565635405078553; --a: 1.4849270635313672; --d: -0.8512367714095253; --s: 0.8069336571457033"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 52; --y: 33; --o: 0.9788465696599828; --a: 0.7286974890670961; --d: -0.6962652439288393; --s: 0.4429822475875227"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 91; --y: 48; --o: 0.5821332952416616; --a: 1.2351482298506113; --d: 0.8898050420144963; --s: 0.03960886968051569"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 99; --y: 34; --o: 0.5653063317133358; --a: 0.548111887826412; --d: -0.4437857011046713; --s: 0.28761410841657375"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 86; --y: 26; --o: 0.20009633151598738; --a: 0.6412973802938251; --d: -0.8681497499715012; --s: 0.4537163083054043"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 2; --y: 77; --o: 0.5471687687725992; --a: 1.3294152760138016; --d: -0.41486643009449775; --s: 0.46181641916508553"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 57; --y: 2; --o: 0.42430638947672383; --a: 0.8842146110800659; --d: 0.5957855694089988; --s: 0.348422523200844"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 18; --y: 96; --o: 0.35848338720279993; --a: 0.6303158463557506; --d: 0.6684750756589071; --s: 0.888287436061107"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 60; --y: 88; --o: 0.7232231798346973; --a: 1.4248664037971024; --d: 0.8683733295469738; --s: 0.8583226019288075"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 18; --y: 29; --o: 0.2631180467918295; --a: 1.1283061100438567; --d: -0.3852051075556395; --s: 0.5974309996681324"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 33; --y: 10; --o: 0.20515328331022786; --a: 0.5144782273462234; --d: -0.35994400934774307; --s: 0.11990729575988146"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 69; --y: 48; --o: 0.5097376479519953; --a: 1.1160624488596975; --d: 0.04380908080168888; --s: 0.8459374609567067"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 63; --y: 78; --o: 0.09440594227407195; --a: 1.3214619161086638; --d: 0.09325987245479972; --s: 0.9997601091541755"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 13; --y: 12; --o: 0.7049942873247796; --a: 1.2670367137290564; --d: 0.13646146777927237; --s: 0.7547920439705178"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 8; --y: 64; --o: 0.9267685844678941; --a: 1.0594680047831389; --d: -0.5004865252058073; --s: 0.5379344492667937"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 74; --y: 46; --o: 0.8761552758897504; --a: 0.9599206313191955; --d: 0.6455112167995476; --s: 0.506043690520533"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 69; --y: 77; --o: 0.46536624573757046; --a: 1.2899971581688414; --d: 0.9752574579692461; --s: 0.09651961101534834"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 31; --y: 94; --o: 0.5714755339530062; --a: 1.0821037241891682; --d: 0.201916603898598; --s: 0.3450401222950332"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 66; --y: 90; --o: 0.48920562291678404; --a: 1.4661518845784507; --d: -0.2721524617975306; --s: 0.4031225206799558"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 96; --y: 99; --o: 0.8367571677385848; --a: 1.21187253232985; --d: -0.62976853603507; --s: 0.10043329043271854"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 93; --y: 74; --o: 0.8487964316229581; --a: 0.6182249158379607; --d: -0.5746522085067127; --s: 0.16257286149677697"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 81; --y: 80; --o: 0.5473467793520386; --a: 1.0356265055836031; --d: -0.8541459816792636; --s: 0.18515487985049384"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 95; --y: 83; --o: 0.14331662794062505; --a: 0.8336784412062452; --d: 0.10039996822266462; --s: 0.3355662284117451"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 79; --y: 93; --o: 0.8839273191600627; --a: 1.1900388880991113; --d: 0.677544001804657; --s: 0.7734519988869126"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 81; --y: 17; --o: 0.2734836022107623; --a: 0.8076765714195115; --d: -0.41358416917759966; --s: 0.2785792660955815"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 73; --y: 43; --o: 0.021002150565963884; --a: 1.464499177198428; --d: -0.9172649447628975; --s: 0.6490358534959773"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 79; --y: 18; --o: 0.6127495164111261; --a: 1.1267665709518533; --d: 0.3605290824023015; --s: 0.2828431456554805"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 8; --y: 28; --o: 0.42383220092157714; --a: 1.2611931449584053; --d: -0.2335274701241139; --s: 0.6372252712466109"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 83; --y: 83; --o: 0.14059318640312624; --a: 1.4252147793207108; --d: 0.18085559457965994; --s: 0.8203129367841202"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 67; --y: 30; --o: 0.38948387120834993; --a: 0.5874200133779128; --d: -0.13326499318268326; --s: 0.752117004293761"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 90; --o: 0.11384722429139482; --a: 1.330044642605339; --d: -0.29366980195580794; --s: 0.20932897716212318"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 33; --y: 50; --o: 0.5262016436245527; --a: 1.0410879315398884; --d: 0.8255007021202272; --s: 0.6626950253429194"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 49; --y: 38; --o: 0.36398640322927345; --a: 1.2596789593423543; --d: 0.3947747180493866; --s: 0.8382355749691379"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 3; --y: 32; --o: 0.6240150656314913; --a: 0.5293383352717544; --d: 0.4094832356367184; --s: 0.775755698554242"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 55; --y: 51; --o: 0.8318822223171283; --a: 1.1403092087705866; --d: -0.2579168883317826; --s: 0.05072795689038956"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 76; --y: 43; --o: 0.11641748989251277; --a: 1.4044702699500646; --d: -0.3453107685402017; --s: 0.7654235920437074"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 67; --y: 37; --o: 0.815588964489155; --a: 0.8667911204815191; --d: 0.51235396854787; --s: 0.3575137592645117"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 49; --y: 76; --o: 0.08795388653097302; --a: 1.075721294523371; --d: 0.43500428114258805; --s: 0.48926548359453714"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 32; --y: 42; --o: 0.36040358365011116; --a: 1.0244659414342754; --d: -0.877515302815778; --s: 0.44548332500113097"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 31; --y: 64; --o: 0.5828989001677014; --a: 0.6218468173810681; --d: -0.9775715984026685; --s: 0.7226196104059208"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 15; --y: 30; --o: 0.5028144021362826; --a: 1.4641138592064418; --d: 0.13606951293640712; --s: 0.18468490661532266"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 24; --y: 7; --o: 0.08703031920073534; --a: 1.3464846444539769; --d: 0.2284417932727667; --s: 0.8519160298631814"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 96; --y: 2; --o: 0.03615712802906734; --a: 0.9220461118765584; --d: 0.0386383251481317; --s: 0.5754463056385479"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 57; --y: 5; --o: 0.32516763262914505; --a: 1.4739107671643692; --d: 0.1083324325213022; --s: 0.17677454362049483"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 1; --o: 0.6047099790954917; --a: 0.9349125556210094; --d: 0.9087893979572761; --s: 0.7227636734062066"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 57; --y: 16; --o: 0.39573775140118284; --a: 0.6656388521687766; --d: -0.04566426103763632; --s: 0.16040092395407446"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 3; --y: 67; --o: 0.1544024359407845; --a: 1.4915178380142178; --d: -0.9653103666999066; --s: 0.17288146442501895"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 26; --y: 37; --o: 0.07299602745679756; --a: 0.8443286513460917; --d: 0.11338276046906381; --s: 0.7895081177046059"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 48; --y: 4; --o: 0.8315476944777487; --a: 0.5037975161889265; --d: -0.6882662884960227; --s: 0.7486943717599452"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 11; --y: 34; --o: 0.999526575473801; --a: 1.1510813241422047; --d: 0.003359043168536413; --s: 0.7939288532319122"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 77; --y: 10; --o: 0.04358241897501358; --a: 1.2188592753110303; --d: -0.2592106033210855; --s: 0.10141241510311283"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 44; --y: 57; --o: 0.847600489829633; --a: 1.4949159260969134; --d: 0.6281509307800079; --s: 0.3711093002146071"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 59; --y: 82; --o: 0.1223134193710338; --a: 0.6550493141842102; --d: -0.4009946693457511; --s: 0.3907883237552221"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 0; --y: 29; --o: 0.8087204117558326; --a: 0.8616648732895706; --d: -0.5378371097969792; --s: 0.7846182209372643"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 9; --y: 62; --o: 0.12380536318522606; --a: 1.327595880570348; --d: 0.9219914026882914; --s: 0.43741861458007425"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 52; --o: 0.5887632774899478; --a: 1.4016324244059506; --d: -0.4318057961536508; --s: 0.4139781927548909"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 55; --y: 28; --o: 0.7327260360021146; --a: 1.46776867109776; --d: 0.7856470725834801; --s: 0.1561025627197139"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 16; --y: 6; --o: 0.4587606556804571; --a: 1.3053450188008466; --d: 0.34576561217568935; --s: 0.894052687351244"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 24; --y: 85; --o: 0.4852755807640732; --a: 0.9702956492452768; --d: -0.16398753285386114; --s: 0.6515676629098099"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 84; --y: 25; --o: 0.4272018624276652; --a: 0.6901679173334367; --d: 0.06067963896098494; --s: 0.6416462460270467"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 40; --y: 93; --o: 0.7784570826477084; --a: 0.8027463427510833; --d: 0.6743479637423717; --s: 0.9753575783115978"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 43; --y: 34; --o: 0.5732245122660407; --a: 1.1116214199032233; --d: -0.36492720301623205; --s: 0.33121044611377104"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 1; --y: 99; --o: 0.946120385376888; --a: 0.5024472533128446; --d: 0.12711629534057778; --s: 0.40554058142324845"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 91; --y: 41; --o: 0.050492232779622226; --a: 1.200929979830461; --d: -0.7368739562101552; --s: 0.537930858982973"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 18; --y: 61; --o: 0.5157575850707521; --a: 1.2035672282913084; --d: 0.5607154293169141; --s: 0.05645447860760244"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 85; --y: 83; --o: 0.15367485984050422; --a: 0.7296510679562986; --d: 0.28269141325427594; --s: 0.5264151208798007"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 2; --y: 82; --o: 0.8649307538003885; --a: 1.4405183789970042; --d: 0.9536647421497926; --s: 0.8217375094700876"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 54; --y: 61; --o: 0.35347656035463526; --a: 1.1859978922437622; --d: 0.11467492142830871; --s: 0.35265189198128444"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 85; --y: 84; --o: 0.6131493532353374; --a: 1.0488943791980425; --d: 0.4665748288816456; --s: 0.20723462206957355"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 2; --y: 17; --o: 0.043340459975769186; --a: 1.3554939014834146; --d: -0.0564962244928493; --s: 0.4469430230957765"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 33; --y: 51; --o: 0.027868489640660243; --a: 0.5931613296447802; --d: 0.26775266925644825; --s: 0.9863527161598256"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 30; --y: 11; --o: 0.8069533852415189; --a: 1.0394965826688416; --d: -0.5377391580133937; --s: 0.171577406514259"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 23; --y: 44; --o: 0.3730433075426618; --a: 1.1000438585531955; --d: 0.06985957741185445; --s: 0.8252141187774873"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 24; --y: 57; --o: 0.2791150708105423; --a: 0.9000290272217215; --d: 0.2006542018616715; --s: 0.054664009354938425"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 97; --y: 7; --o: 0.6043934161617395; --a: 1.0801340475305292; --d: 0.4136397193944754; --s: 0.15533507229262478"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 63; --y: 14; --o: 0.3882067069006243; --a: 1.0901111175868254; --d: 0.691633504782859; --s: 0.7132606474023075"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 1; --y: 92; --o: 0.3400408915383488; --a: 1.1831482733978733; --d: -0.2984929977514703; --s: 0.3141029659914498"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 42; --y: 77; --o: 0.9760950017965864; --a: 1.3810731009993489; --d: 0.6579246039966407; --s: 0.8578202641272061"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 14; --y: 14; --o: 0.7424127727514311; --a: 0.8600734183564749; --d: 0.7993395369984166; --s: 0.3774936026278597"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 62; --y: 0; --o: 0.16473860065900858; --a: 1.4200581015289568; --d: 0.4734302733472546; --s: 0.4211590572968946"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 74; --y: 69; --o: 0.4814879243197183; --a: 1.0872920005332989; --d: 0.3037613145856768; --s: 0.7932834605124246"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 72; --y: 51; --o: 0.36602787894036726; --a: 1.302598009350778; --d: -0.22523118191464686; --s: 0.8743908933916777"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 65; --y: 44; --o: 0.4622594936717068; --a: 0.5451666174896745; --d: -0.3501562635337341; --s: 0.40966667997753636"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 25; --y: 74; --o: 0.9753640913859958; --a: 1.2380958776866422; --d: -0.07039303728446011; --s: 0.2363095238982238"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 76; --y: 35; --o: 0.5363814926826407; --a: 0.9170000228749617; --d: -0.9651553855311583; --s: 0.5041615886835176"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 55; --y: 54; --o: 0.6786480751103787; --a: 0.7978854691405846; --d: -0.5180230473315133; --s: 0.9047114357385959"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 57; --y: 81; --o: 0.8651438474496775; --a: 1.3335559184262067; --d: -0.4128944247505584; --s: 0.5105912820217655"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 77; --y: 8; --o: 0.24183320144674703; --a: 1.216009333543398; --d: 0.49642114823785644; --s: 0.9536567911928051"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 21; --y: 49; --o: 0.279812222534491; --a: 1.337171545455886; --d: 0.31159048348268037; --s: 0.06425242207488324"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 52; --y: 95; --o: 0.6004568259464951; --a: 1.268647394478331; --d: 0.23354977423607481; --s: 0.07362917623611742"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 15; --y: 45; --o: 0.2969293669680011; --a: 1.4306993293456667; --d: 0.24341374394727078; --s: 0.3349359206986622"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 91; --y: 19; --o: 0.5865723256917634; --a: 0.8329425993128936; --d: 0.2728091198702334; --s: 0.7300083596705258"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 80; --y: 3; --o: 0.6097166963663696; --a: 0.5981864389775207; --d: 0.5756040332853112; --s: 0.840896967701539"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 33; --y: 23; --o: 0.27481391255924703; --a: 0.5715580475436517; --d: -0.8035067793205419; --s: 0.39670806330421127"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 19; --y: 51; --o: 0.656873716472882; --a: 1.113035761247437; --d: -0.1082898910222303; --s: 0.24763212430253523"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 60; --y: 18; --o: 0.4205555764831037; --a: 1.0627586638944362; --d: 0.5201178129434765; --s: 0.6528140258109492"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 40; --y: 35; --o: 0.14364625284141375; --a: 1.099025117326622; --d: 0.6334098396345351; --s: 0.2347188537761149"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 67; --y: 58; --o: 0.6582620819767111; --a: 0.6151096972944405; --d: 0.09172731478582641; --s: 0.7982428495661493"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 53; --y: 15; --o: 0.4872506695292318; --a: 1.171191453537041; --d: -0.43642172282680924; --s: 0.44162959744917907"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 5; --y: 96; --o: 0.7295270269604415; --a: 1.2222437803854136; --d: 0.461488657822672; --s: 0.164704223099698"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 36; --y: 17; --o: 0.6059628624886411; --a: 0.6747661355154917; --d: 0.2649264544756851; --s: 0.7558226940049835"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 36; --y: 43; --o: 0.5552701636735953; --a: 1.1889835972812814; --d: 0.8591112677834394; --s: 0.3352557459240719"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 11; --y: 40; --o: 0.7390809997265437; --a: 1.114339132639464; --d: -0.9981355652197377; --s: 0.5385001993911711"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 56; --y: 5; --o: 0.9961552018549187; --a: 1.401207100419373; --d: -0.15372432219995114; --s: 0.6548432322539095"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 4; --y: 92; --o: 0.6148404459785763; --a: 0.9087934606382371; --d: -0.9541378169154222; --s: 0.49291826195199406"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 31; --y: 17; --o: 0.06268077214598189; --a: 1.229198519005389; --d: -0.39090015802606537; --s: 0.0949562709234657"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 25; --y: 26; --o: 0.8988085858335257; --a: 1.0053914961451926; --d: -0.13936900225195314; --s: 0.6322111257188643"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 31; --y: 80; --o: 0.19684453994169537; --a: 0.8640987438406258; --d: 0.08328697641591143; --s: 0.5653603408275807"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 69; --y: 27; --o: 0.4045643289308962; --a: 1.071687424509735; --d: -0.47842175603378356; --s: 0.6431198661355262"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 91; --y: 75; --o: 0.9965140617930734; --a: 1.1462500818655503; --d: -0.2998588048313664; --s: 0.41524345736891966"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 76; --y: 20; --o: 0.6807541066355567; --a: 0.6525718857676828; --d: 0.10310960232850741; --s: 0.013352472959049733"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 34; --y: 60; --o: 0.5286664123745257; --a: 1.088536922865802; --d: -0.3001882355326333; --s: 0.2008947116929103"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 35; --y: 38; --o: 0.31659643835086415; --a: 1.2630634250943122; --d: -0.11803176748631605; --s: 0.30588102245045046"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 1; --y: 87; --o: 0.866837031042649; --a: 1.4850875131006522; --d: -0.7392458909605861; --s: 0.8989925661205342"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 73; --y: 41; --o: 0.021684625355169018; --a: 0.858454132737279; --d: 0.25917458476838817; --s: 0.005141017841319373"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 90; --y: 98; --o: 0.7044779876845304; --a: 0.8646485906918508; --d: -0.5073907766033829; --s: 0.8276341788217234"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 42; --y: 47; --o: 0.07218921551931823; --a: 1.2681527951481528; --d: -0.9462724176711439; --s: 0.41255291357869717"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 18; --y: 11; --o: 0.2366112527238644; --a: 0.7965388788435004; --d: -0.36595484025325; --s: 0.9801105112584605"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 11; --y: 20; --o: 0.2570428143016179; --a: 1.0854254816615607; --d: 0.2288215843494137; --s: 0.9634103766702307"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 61; --y: 73; --o: 0.762570368168882; --a: 0.99746775983492; --d: -0.4032669215953031; --s: 0.8621131105043938"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 93; --o: 0.2607470621902761; --a: 0.8245093815000246; --d: -0.7363044767859512; --s: 0.6555605926571766"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 40; --o: 0.5277341200291581; --a: 0.8403691910122388; --d: -0.3214532072850429; --s: 0.8997474795563196"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 86; --y: 96; --o: 0.7052541523249394; --a: 0.8454350362867258; --d: -0.20593415827030315; --s: 0.24273801032103282"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 50; --y: 93; --o: 0.9766585325619499; --a: 0.6564368292998326; --d: 0.22565515237637257; --s: 0.50677895634369"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 89; --y: 44; --o: 0.9397689587980615; --a: 1.3084565756889577; --d: 0.77480551349977; --s: 0.9469815342284156"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 13; --y: 39; --o: 0.31524051262163066; --a: 1.1036820037496866; --d: 0.5907099262312028; --s: 0.36061573647019407"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 70; --y: 31; --o: 0.6725763422681803; --a: 1.3606065661767965; --d: -0.3838104618400191; --s: 0.27382026081368416"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 96; --o: 0.39836191801248466; --a: 0.5701494828779379; --d: 0.06211541964519629; --s: 0.8363993466341189"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 47; --y: 70; --o: 0.1262520447647788; --a: 0.5587340128716196; --d: -0.6914646549658228; --s: 0.5134114823774516"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 70; --y: 5; --o: 0.5372035726946716; --a: 0.7362305783677057; --d: -0.6825734103385508; --s: 0.7379588671940487"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 50; --y: 48; --o: 0.36051018847688376; --a: 0.65660823105151; --d: 0.9194518345675027; --s: 0.7564290892384788"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 13; --y: 43; --o: 0.6912243348842433; --a: 1.0602249280137657; --d: -0.7419838620397949; --s: 0.4788882531947112"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 13; --y: 71; --o: 0.2853978658687819; --a: 0.8687399250567982; --d: -0.680448949753794; --s: 0.5929150438383719"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 42; --y: 40; --o: 0.22849967379489544; --a: 1.101305338316838; --d: 0.6816471710979912; --s: 0.2426962184515764"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 17; --y: 70; --o: 0.061283897086350736; --a: 1.0478591821132428; --d: -0.09009285850571391; --s: 0.1903453807129556"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 46; --y: 54; --o: 0.4362180915561309; --a: 0.6637754200681025; --d: -0.6907712502206875; --s: 0.30357697783414683"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 68; --o: 0.7391179027229673; --a: 1.1814794226568903; --d: 0.5235156568278097; --s: 0.9925922841890826"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 65; --y: 73; --o: 0.14426317757049167; --a: 0.9340915638402711; --d: 0.20830221604935817; --s: 0.9058724727620027"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 37; --y: 98; --o: 0.6149082190465758; --a: 1.0532959609157158; --d: -0.43909684659670933; --s: 0.32466919415038387"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 97; --y: 7; --o: 0.4960863088928762; --a: 1.4240069988823147; --d: -0.42259696534841007; --s: 0.5349439594750314"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 4; --y: 72; --o: 0.1712059502889094; --a: 0.9115850891403332; --d: 0.7715598645585948; --s: 0.1457576373645686"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 7; --y: 65; --o: 0.8226224659770209; --a: 0.9779939724932449; --d: -0.20063357184964747; --s: 0.3415564650136036"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 85; --y: 74; --o: 0.7840914339715459; --a: 0.7026387775871317; --d: 0.2916026991200167; --s: 0.24625166213272154"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 50; --y: 41; --o: 0.9782697047950366; --a: 0.5607054976485453; --d: 0.22017989474680943; --s: 0.8327415317953251"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 36; --y: 54; --o: 0.6438216510621788; --a: 0.613139891963578; --d: 0.0036364126161583243; --s: 0.20111795514399344"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 69; --y: 28; --o: 0.28192972511726455; --a: 1.1217348123952011; --d: 0.14961630490239397; --s: 0.4752696108332761"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 3; --y: 55; --o: 0.32662032047125544; --a: 0.8438562775223191; --d: -0.7105600399961656; --s: 0.399427545858017"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 38; --y: 91; --o: 0.8893529678304153; --a: 0.7087114143673945; --d: 0.7136063702260151; --s: 0.21364985058766428"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 32; --y: 12; --o: 0.8154567043688272; --a: 0.5344949928085903; --d: -0.34826623324983563; --s: 0.829696841478865"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 59; --y: 72; --o: 0.27047078580008077; --a: 0.8405086601960936; --d: 0.12617266382002157; --s: 0.002468917366630796"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 80; --y: 55; --o: 0.9432453743066378; --a: 0.6300675109070797; --d: 0.6881816462942516; --s: 0.900429621267435"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 12; --y: 66; --o: 0.602207800749496; --a: 1.3891917247618917; --d: 0.867612263673609; --s: 0.6707386556563197"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 28; --y: 31; --o: 0.8673755861879611; --a: 0.6097138615507598; --d: -0.9236569890068131; --s: 0.9360542271508312"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 21; --y: 44; --o: 0.9224152170993001; --a: 0.7522846302843138; --d: -0.5597935720064764; --s: 0.9937251241243525"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 53; --y: 29; --o: 0.39103885201972965; --a: 1.2112601513420398; --d: -0.6261470338059403; --s: 0.19324274767015082"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 30; --y: 54; --o: 0.6299853794579591; --a: 1.2160375847207932; --d: 0.6401633694488469; --s: 0.5195868598450692"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 0; --y: 1; --o: 0.1750647017133713; --a: 0.878027592060445; --d: -0.12324351427820579; --s: 0.27392534486730913"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 95; --y: 80; --o: 0.5499221937210528; --a: 1.2071834764507172; --d: 0.0009085478507588896; --s: 0.7349786205225621"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 59; --y: 27; --o: 0.4999824376314286; --a: 0.7874171080376327; --d: -0.10741060584146478; --s: 0.07083334492617"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 11; --y: 36; --o: 0.6328816268690849; --a: 1.4951165928201489; --d: 0.7541407907736022; --s: 0.1776207924137354"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 93; --y: 39; --o: 0.21594354355788137; --a: 1.1224877598132725; --d: -0.27382756315576096; --s: 0.6842591075757654"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 93; --y: 98; --o: 0.9851740523119996; --a: 0.6667247018554638; --d: -0.33902299217635656; --s: 0.27690949702656664"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 49; --y: 50; --o: 0.11786018382612151; --a: 1.1782012222660927; --d: 0.9935446499100178; --s: 0.5501704059520174"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 38; --y: 81; --o: 0.5658677979722297; --a: 1.0072649428338674; --d: 0.7969887172916192; --s: 0.8139218946502695"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 40; --y: 6; --o: 0.08477454525516626; --a: 1.0214354399211374; --d: 0.7194648504670198; --s: 0.9121191133365902"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 2; --y: 78; --o: 0.10579214536095893; --a: 1.0883895480950416; --d: 0.27328734035406654; --s: 0.7258732977960121"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 93; --y: 1; --o: 0.9481736429995846; --a: 1.425077427237331; --d: 0.26268869016399066; --s: 0.4822181715800813"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 70; --y: 63; --o: 0.8400972792779473; --a: 0.6347678114968944; --d: -0.9791307531010602; --s: 0.8190265826421714"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 91; --y: 73; --o: 0.5657831147261387; --a: 1.006979425446048; --d: -0.21882642536649266; --s: 0.4492265662209687"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 77; --y: 49; --o: 0.24715942357767595; --a: 1.4828450351416262; --d: -0.914919422714239; --s: 0.9582550545021344"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 39; --y: 2; --o: 0.056274703519457736; --a: 0.6286146831488939; --d: 0.6343090531517848; --s: 0.6222248592379576"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 58; --y: 28; --o: 0.7361886951251049; --a: 1.4496024872234539; --d: 0.8940607878586238; --s: 0.05896080212867982"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 21; --y: 93; --o: 0.3961807178848844; --a: 1.1769327104233516; --d: 0.5359149097924822; --s: 0.27205290480717403"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 87; --y: 66; --o: 0.49808111278788525; --a: 0.7082409710905548; --d: -0.9901654016607848; --s: 0.1274401045283573"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 12; --y: 12; --o: 0.35808293292580107; --a: 0.9804427632240009; --d: -0.5258085449195247; --s: 0.625852811375373"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 64; --y: 4; --o: 0.5444700877495356; --a: 0.6707527246689222; --d: 0.01673224841964638; --s: 0.731813439536039"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 14; --y: 33; --o: 0.9961041876291037; --a: 0.7827104193813454; --d: 0.6424706926454129; --s: 0.7342171825406061"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 26; --y: 29; --o: 0.3656267838828098; --a: 0.7157726445155701; --d: -0.7887255086737857; --s: 0.428431899101797"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 63; --y: 89; --o: 0.6618227872952529; --a: 0.8918487021993533; --d: -0.7259051965923673; --s: 0.5283511579175242"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 62; --y: 69; --o: 0.31591323066083543; --a: 1.3428037421828614; --d: -0.2759516577222758; --s: 0.796932968980236"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 29; --y: 42; --o: 0.6687489220362552; --a: 0.5223804416778532; --d: 0.5793889506044971; --s: 0.17388294779837166"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 62; --y: 54; --o: 0.5996206642713329; --a: 0.9126201246258909; --d: 0.6532743310081606; --s: 0.3110346745828312"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 4; --y: 13; --o: 0.9888251809789896; --a: 0.6970636752663468; --d: -0.6362326425772253; --s: 0.9644569056609222"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 55; --y: 15; --o: 0.6547786066979504; --a: 1.0780440658292518; --d: 0.0055655732990835816; --s: 0.4532651190052803"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 99; --y: 36; --o: 0.9663910072260682; --a: 1.4977895415154476; --d: -0.6861204801554597; --s: 0.4514501862290674"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 50; --y: 99; --o: 0.8565830757163393; --a: 1.234081342561851; --d: 0.24635767860064473; --s: 0.29942052474260095"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 17; --y: 35; --o: 0.059163272225569274; --a: 1.2039306047852765; --d: -0.696007343034585; --s: 0.020208548728722908"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 61; --y: 97; --o: 0.22257022442381902; --a: 0.941129886534253; --d: -0.09288114677514603; --s: 0.6636682667640885"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 62; --y: 98; --o: 0.2913592053322922; --a: 1.3196334121859716; --d: -0.8068174086529036; --s: 0.4453991332202494"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 41; --y: 91; --o: 0.9788688989823939; --a: 0.5757685917969397; --d: -0.9613113580217059; --s: 0.26186100608803087"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
    <svg
      class="rain__drop"
      preserveAspectRatio="xMinYMin"
      viewBox="0 0 5 50"
      style="--x: 26; --y: 99; --o: 0.4951381410357354; --a: 1.137379066092341; --d: 0.5393533938476924; --s: 0.23092095425704362"
    >
      <path
        stroke="none"
        d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z"
      />
    </svg>
  </div>
</main>

<style scoped>
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1000;
  }
  .rain__drop {
    -webkit-animation-delay: calc(var(--d) * 1s);
    animation-delay: calc(var(--d) * 1s);
    -webkit-animation-duration: calc(var(--a) * 1s);
    animation-duration: calc(var(--a) * 1s);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: drop;
    animation-name: drop;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    height: 30px;
    left: calc(var(--x) * 1%);
    position: absolute;
    top: calc((var(--y) + 50) * -1px);
    z-index: -1000;
  }
  .rain__drop path {
    fill: var(--secondary-color);
    opacity: var(--o);
    transform: scaleY(calc(var(--s) * 1.5));
    z-index: -1000;
  }
  @-webkit-keyframes drop {
    90% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
      transform: translateY(100vh);
    }
  }
  @keyframes drop {
    90% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
      transform: translateY(100vh);
    }
  }
</style>
