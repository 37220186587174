<div>
  Copyright &copy; 2022
</div>

<style lang="postcss" scoped>
  div{
    background-color: var(--dark-background);

    @apply grid grid-cols-1 place-items-center py-2 align-middle;
  }
</style>