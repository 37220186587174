import Home from "../Home/Home.svelte"
import Support from "../Support/Support.svelte"
import QA from "../QA/QA.svelte"

export const routes = {
    // Exact path
    '/': Home,
    '/support': Support,
    '/qa': QA

}